<template>
  <UiTourDriver
    v-if="isTourMounted"
    ref="driver"
    :steps
    :tour="TOURS_IDS.PAYMENT_LINK"
    :highlight
    @destroyed="handleDestroy"
  />
</template>

<script setup lang="ts">
import type { DriveStep } from 'driver.js'
import { POPUPS } from '../dynamic/maps'
import type { UiTourDriver } from '#build/components'
import { SETTING_CODES, TOURS_IDS } from '~/constants'
import { useUiStore } from '~/store/ui'
import { exclusiveTableMockData, leadPaymentTokensMockData } from '~/mocks/paymentTokens'
import { SDRQueueLead } from '~/mocks/SDRQueue'

const driver = ref<InstanceType<typeof UiTourDriver> | null>(null)

const highlight = ref<DriveStep>({
  element: '#generate_payment_link',
  popover: {
    title: 'Token payment link',
    description: `Exciting News! 
        You can now book Exclusive Units directly in the CRM and generate a payment link instantly.`,
    nextBtnText: 'Learn more',
    side: 'right',
    align: 'center',
  },
})

const goToLead = async () => {
  await navigateTo({
    name: 'leads-id',
    params: { id: '1' },
    query: { tour: 'active' },
    hash: '#token',
  })

  await useWaitForElement(STEP_IDS.PAYMENT_TOKEN_PROPERTY)
}

const goToExclusivesTable = async () => {
  await navigateTo({
    name: 'properties-exclusives',
  })

  await useWaitForElement(STEP_IDS.GENERATE_PAYMENT_LINK)
}

const statusToggleSelector = `#status_${exclusiveTableMockData[1].id}_toggle`
const statusMenuSelector = `#status_${exclusiveTableMockData[1].id}_custom_menu`
const actionsSelector = `#actions_${exclusiveTableMockData[1].id}`

const toggleMenu = async () => {
  const statusElement = (await useWaitForElement(statusToggleSelector)) as HTMLElement

  if (statusElement) {
    await new Promise((resolve) => setTimeout(resolve, 500))

    statusElement.click()
  }

  await useWaitForElement(statusMenuSelector)
}

const UNIT_STATUS_ID = 'unit_status'

const LEAD_PAYMENT_TOKENS_ID = 'lead-payment-tokens'

const LEAD_PAYMENT_TOKENS_CONTAINER = `lead_payment_tokens_container`

const STEP_IDS = {
  GENERATE_PAYMENT_LINK: '#generate_payment_link',
  PAYMENT_METHODS: '#payment_methods',
  PAYMENT_TOKEN_PROPERTY: `#payment_token_property_${leadPaymentTokensMockData[0].id}`,
  UNIT_STATUS: `#${UNIT_STATUS_ID}`,
  LEAD_PAYMENT_TOKENS: `#${LEAD_PAYMENT_TOKENS_CONTAINER}`,
  POPUP: '#popup',
  PAYMENT_TOKEN_FILES: `#payment_token_files_${leadPaymentTokensMockData[1].id}`,
}

const createUnitStatusContainer = () => {
  const toggleBlock = document.querySelector(statusToggleSelector)
  const menuBlock = document.querySelector(statusMenuSelector)
  const actionsBlock = document.querySelector(actionsSelector)

  if (toggleBlock && menuBlock && actionsBlock) {
    if (document.querySelector(`#${UNIT_STATUS_ID}`)) {
      // Block already exists

      return
    }

    const { x, y, height } = toggleBlock.getBoundingClientRect()
    const { height: menuHeight } = menuBlock.getBoundingClientRect()
    const { x: actionsX, width: actionsWidth } = actionsBlock.getBoundingClientRect()

    const unitStatus = document.createElement('div')
    unitStatus.id = UNIT_STATUS_ID

    unitStatus.style.cssText = `
      height: ${height + 2 + menuHeight}px; 
      left: ${x}px; 
      position: absolute; 
      top: ${y}px; 
      width: ${actionsX - x + actionsWidth}px; 
      pointer-events: none;
    `

    document.body.appendChild(unitStatus)
  }
}

const removeUnitStatusContainer = () => {
  const unitStatus = document.querySelector(`#${UNIT_STATUS_ID}`)

  if (unitStatus) {
    unitStatus.remove()
  }
}

const leadPaymentTokensSelector = `#${LEAD_PAYMENT_TOKENS_ID}`
const leadPaymentTokensMenuSelector = `#${LEAD_PAYMENT_TOKENS_ID}_custom_menu`
const leadPaymentTokensMoreSelector = `#${LEAD_PAYMENT_TOKENS_ID}-more`

const toggleLeadPaymentTokensMenu = async () => {
  await useWaitForElement('#timer-for-call')

  const button = (await useWaitForElement(leadPaymentTokensMoreSelector)) as HTMLButtonElement

  if (button) {
    await new Promise((resolve) => setTimeout(resolve, 500))

    button.click()
  }

  await useWaitForElement(leadPaymentTokensMenuSelector)
}

const createLeadPaymentTokensContainer = () => {
  const toggleBlock = document.querySelector(leadPaymentTokensSelector)
  const menuBlock = document.querySelector(leadPaymentTokensMenuSelector)

  if (toggleBlock && menuBlock) {
    if (document.querySelector(`#${LEAD_PAYMENT_TOKENS_CONTAINER}`)) {
      // Block already exists

      return
    }

    const { x, y, width, height } = toggleBlock.getBoundingClientRect()
    const { height: menuHeight } = menuBlock.getBoundingClientRect()

    const leadPaymentTokens = document.createElement('div')
    leadPaymentTokens.id = LEAD_PAYMENT_TOKENS_CONTAINER

    leadPaymentTokens.style.cssText = `
      height: ${height + 2 + menuHeight}px; 
      left: ${x}px; 
      position: absolute; 
      top: ${y}px; 
      width: ${width}px; 
      pointer-events: none;
    `

    document.body.appendChild(leadPaymentTokens)
  }
}

const removeLeadPaymentTokensContainer = () => {
  const leadPaymentTokensContainer = document.querySelector(`#${LEAD_PAYMENT_TOKENS_CONTAINER}`)

  if (leadPaymentTokensContainer) {
    leadPaymentTokensContainer.remove()
  }
}

const steps = computed<DriveStep[]>(() => [
  {
    element: STEP_IDS.GENERATE_PAYMENT_LINK,
    popover: {
      title: '1/8 Generate payment link',
      description: `In the table of exclusives, you can create a token payment link for a specific unit.
        Please note that the reservation will remain active for ${holdTime.value}.
        If the payment is not confirmed within this timeframe,
        the unit will become available to everyone again.`,
      side: 'right',
      align: 'center',
      onNextClick: async () => {
        if (driver.value?.driverObj) {
          uiStore.showPopup(POPUPS.GENERATE_PAYMENT_LINK)

          await useWaitForElement(STEP_IDS.PAYMENT_METHODS)

          if (driver.value?.driverObj) {
            driver.value.driverObj.moveNext()
          }
        }
      },
    },
  },
  {
    element: STEP_IDS.PAYMENT_METHODS,
    popover: {
      title: '2/8 Select payment method',
      description: `Choose how the client will pay the token amount—via a direct payment link
        or other methods—and book the unit instantly!`,
      side: 'right',
      align: 'start',
      onPrevClick: () => {
        uiStore.cleanPopup()

        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: async () => {
        await goToLead()

        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: STEP_IDS.PAYMENT_TOKEN_PROPERTY,
    popover: {
      title: '3/8 Copy payment link',
      description: `After booking a unit, you can view the booking details and payment status on the Lead page.
        You can also copy the payment link from there.`,
      side: 'top',
      align: 'center',
      onPrevClick: async () => {
        await goToExclusivesTable()

        uiStore.showPopup(POPUPS.GENERATE_PAYMENT_LINK)

        await useWaitForElement(STEP_IDS.PAYMENT_METHODS)

        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: async () => {
        if (driver.value?.driverObj) {
          await goToExclusivesTable()

          await toggleMenu()

          createUnitStatusContainer()

          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: STEP_IDS.UNIT_STATUS,
    popover: {
      title: '4/8 Review details',
      description: `For units on hold awaiting payment confirmation, 
        find all details in the Exclusive table. 
        The link generation option is not available.`,
      side: 'top',
      align: 'end',
      onPrevClick: async () => {
        removeUnitStatusContainer()

        await goToLead()

        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: async () => {
        removeUnitStatusContainer()

        await goToLead()

        await useWaitForElement(`#${LEAD_PAYMENT_TOKENS_ID}`)

        await toggleLeadPaymentTokensMenu()

        createLeadPaymentTokensContainer()

        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: STEP_IDS.LEAD_PAYMENT_TOKENS,
    popover: {
      title: '5/8 Add confirmation',
      description: `Reserve multiple units for the same lead and track their status on the Lead page.
        Attach payment confirmations with a quick click of the banner button.`,
      side: 'top',
      align: 'end',
      onPrevClick: async () => {
        removeLeadPaymentTokensContainer()

        await goToExclusivesTable()

        await toggleMenu()

        createUnitStatusContainer()

        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: async () => {
        removeLeadPaymentTokensContainer()

        uiStore.showPopup(POPUPS.CONFIRM_PAYMENT, {
          lead: SDRQueueLead,
          holdingHours: holdTime.value,
          tokenId: leadPaymentTokensMockData[0].id,
        })

        await useWaitForElement(STEP_IDS.POPUP)

        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: STEP_IDS.POPUP,
    popover: {
      title: '6/8 Add confirmation',
      description: `To confirm payments made through other methods, 
        add the buyer's details, passport, and payment confirmation. 
          Finance will review and verify the documents.`,
      side: 'left',
      align: 'end',
      onPrevClick: async () => {
        uiStore.cleanPopup()

        await useWaitForElement(`#${LEAD_PAYMENT_TOKENS_ID}`)

        await toggleLeadPaymentTokensMenu()

        createLeadPaymentTokensContainer()

        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: async () => {
        uiStore.cleanPopup()

        const element = await useWaitForElement(STEP_IDS.PAYMENT_TOKEN_FILES)

        if (element) {
          element.scrollIntoView({
            behavior: 'instant',
          })
        }

        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: STEP_IDS.PAYMENT_TOKEN_FILES,
    popover: {
      title: '7/8 Update files',
      description: `For alternative payment methods, 
        you can update or change payment confirmation files directly on this page.`,
      side: 'top',
      align: 'start',
      onPrevClick: async () => {
        uiStore.showPopup(POPUPS.CONFIRM_PAYMENT, {
          lead: SDRQueueLead,
          holdingHours: holdTime.value,
          tokenId: leadPaymentTokensMockData[0].id,
        })

        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })

        await useWaitForElement(STEP_IDS.POPUP)

        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
      onNextClick: () => {
        if (driver.value?.driverObj) {
          driver.value.driverObj.moveNext()
        }
      },
    },
  },
  {
    element: '#no-selector',
    popover: {
      title: '8/8 Unit fully booked',
      description: `Once the payment is confirmed, the unit is fully booked for your lead. 
        You can then create a deal to move forward and earn your commission.`,
      side: 'top',
      align: 'center',
      onPrevClick: () => {
        if (driver.value?.driverObj) {
          driver.value.driverObj.movePrevious()
        }
      },
    },
  },
])

const holdTime = ref('')

const uiStore = useUiStore()

const isTourMounted = ref(false)

const getPaymentTokenHoldTime = async () => {
  try {
    const { settings } = await useGetSettingsByCode<{
      settings: {
        payment_token_hold_time: string
      }
    }>(SETTING_CODES.PAYMENT_TOKEN_HOLD_TIME)

    holdTime.value = settings[SETTING_CODES.PAYMENT_TOKEN_HOLD_TIME]
  } catch {
    uiStore.showSnackBanner('Failed to fetch hold time, please try again later', 'error')
  }
}

const route = useRoute()

const handleDestroy = () => {
  uiStore.cleanPopup()

  uiStore.cleanActiveTourId()

  if (route.name !== 'properties-exclusives') {
    navigateTo({
      name: 'properties-exclusives',
    })
  }
}

onMounted(async () => {
  await getPaymentTokenHoldTime()

  isTourMounted.value = true
})
</script>

<style scoped lang="scss"></style>
