const getIsVisible = (element: Element): boolean => {
  const style = window.getComputedStyle(element)
  return (
    style.display !== 'none' &&
    style.visibility !== 'hidden' &&
    element.getBoundingClientRect().width > 0 &&
    element.getBoundingClientRect().height > 0
  )
}

export default (selector: string, timeout: number = 5000, intervalMs: number = 100): Promise<Element> => {
  return new Promise((resolve, reject) => {
    const initialElement = document.querySelector(selector)

    if (initialElement && getIsVisible(initialElement)) {
      return resolve(initialElement)
    }

    const startTime = Date.now()
    let intervalId: ReturnType<typeof setInterval> | null = null

    const cleanup = () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }

    const checkElement = () => {
      const elapsed = Date.now() - startTime
      const element = document.querySelector(selector)

      if (element && getIsVisible(element)) {
        cleanup()

        resolve(element)
      } else if (elapsed >= timeout) {
        cleanup()

        reject(new Error(`Element "${selector}" not found within ${timeout}ms`))
      }
    }

    intervalId = setInterval(checkElement, intervalMs)
  })
}
